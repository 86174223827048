<template>
  <div>
    <CheckoutHeader :back-button-action="backBtnAction" class="-mb-4">
      <template v-slot:back>
        {{ $t("checkout.back_checkout_edit") }}
      </template>
      <template v-slot:header>
        <template>
          <span data-cy="choose-wizard_control-text">
            3 {{ $t("checkout.of") }} 4 &mdash;
          </span>
        </template>
        <strong data-cy="choose-wizard_control_choose-text">
          {{ $t("checkout.credit_card") }}</strong
        >
      </template>
      <template v-slot:sub-header>
        {{ $t("plan") }}
        <strong data-cy="payment_method-plan-text"
          >{{ getDefaultPlanName }}
          {{ $t("recurrences_num")[getChosenPlan?.recurrencePeriod] }}
        </strong>
        &mdash;
        <span data-cy="choose-plan_price-text" v-html="planPrice" />
      </template>
    </CheckoutHeader>
    <div class="my-5 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 m-auto">
        <label>{{ $t('form.card_number') }}</label>
        <div id="card-number-element" class="border"></div>
        <label for="name">{{ $t('form.validity') }}</label>
        <div id="card-expiry-element" class="border"></div>
        <label for="name">{{ $t('form.security_code') }}</label>
        <div id="card-cvc-element" class="border"></div>
    
        <submit-button-default
          style="width: 100%; max-width: 100%;"
          :text="$t('form.pay_and_checkout')"
          :fatButton="true"
          :showIcon="true"
          data-cy="checkout-confirm-btn"
          @click.native="next()"
        />
      
</div>
    <div
      v-if="newPaymentInfo.error === true"
      class="w-2/3 border border-nexred-600 bg-nexred-100 text-nexred-800 p-5 text-left inline-block"
    >
      <p class="font-semibold">
        {{ $t("checkout.changePayment.creditCard.creditCardError") }}
      </p>

      <p class="font-semibold mt-2">
        {{ $t("checkout.changePayment.creditCard.suport") }}
      </p>
    </div>
  </div>
</template>
<script src="https://js.stripe.com/v3/"></script>

<script>
import CheckoutHeader from "@/components/Checkout/Header.vue";
import VCreditCard from "@/components/CreditCard/FormCreditCard.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { mapActions, mapGetters } from "vuex";
import {
  CHECKOUT_MODULE,
  CREATE_CREDIT_CARD_TOKEN,
  EXEC_CHECKOUT,
  GET_CHOSEN_PLAN,
  CHANGE_PENDING
} from "@/store/constants/checkout";
import { STORE_MODULE } from "@/store/constants/store";
import Validation from "../plugins/Validation";
import {
  CHECKOUT_CREDIT_CARD_VIEW,
  CHECKOUT_CREDIT_CARD_SUCCESS,
  PAYMENT_METHOD_SELECTOR_VIEW,
} from "@/router/constants";
import { CHANGE_LOADING } from "@/store/constants/login";
import { Currency } from "@/constants";
import {loadStripe} from '@stripe/stripe-js';


export default {
  name: CHECKOUT_CREDIT_CARD_VIEW,
  components: {
    CheckoutHeader,
    VCreditCard,
    SubmitButtonDefault,
  },
  data() {
    return {
      isComplete: false,
      newPaymentInfo: {},
      cardElement:null,
      stripe: null,
      token: null,
      creditCardData: {
        name: null,
        cardNumber: null,
        expiration: null,
        security: null,
        company: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getChosenPlan: `${CHECKOUT_MODULE}/${GET_CHOSEN_PLAN}`,
      storeProfile: `${STORE_MODULE}/storeProfile`,
      eventGtm: 'eventGtm'
    }),
    isFilled() {
      for (const key in this.creditCardData) {
        if ([null, ""].includes(this.creditCardData[key])) {
          return false;
        }
      }
      return true;
    },
    isValidCreditCard() {
      return Validation.validateCreditCard(this.creditCardData);
    },
    planPrice() {
      return `${Currency[this.getChosenPlan?.corrency]} ${
        this.getChosenPlan?.price
      }<small>,00
      <em>${
        this.$t("recurrences_freq_num")[this.getChosenPlan?.recurrencePeriod]
      }</em>
      </small>`;
    },
    getDefaultPlanName() {
      return this.$t("plan_default_name")[this.getChosenPlan?.name];
    },
  },
  mounted() {
    const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    this.stripe = stripe
    var style = {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '35px',
        fontSize: '15px',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    };
    const elements = stripe.elements()
    var cardNumberElement = elements.create('cardNumber', {style: style});
    cardNumberElement.mount('#card-number-element');

    var cardExpiryElement = elements.create('cardExpiry', {style: style});
    cardExpiryElement.mount('#card-expiry-element');

    var cardCvcElement = elements.create('cardCvc', {style: style});
    cardCvcElement.mount('#card-cvc-element');

    this.cardElement = cardNumberElement
  },
  methods: {
    ...mapActions({
      tokenizeCard: `${CHECKOUT_MODULE}/${CREATE_CREDIT_CARD_TOKEN}`,
      execCheckout: `${CHECKOUT_MODULE}/${EXEC_CHECKOUT}`,
      changeLoading: CHANGE_LOADING,
      changePending: `${CHECKOUT_MODULE}/${CHANGE_PENDING}`,
    }),
    backBtnAction() {
      return this.$router.push({
        name: PAYMENT_METHOD_SELECTOR_VIEW,
      });
    },
    onChangeCreditCardData(values) {
      for (const key in values) {
        this.creditCardData[key] = values[key];
      }
    },
    onChangeCreditCardCompany(value) {
      this.creditCardData["company"] = value;
    },
    parseCreditCardData() {
      return {
        card_expiration: this.creditCardData.expiration,
        card_number: this.creditCardData.cardNumber,
        card_cvv: this.creditCardData.security,
        payment_method_code: "credit_card",
      };
    },
    async next() {
      this.changeLoading(true);
      try {
        const data = await this.tokenData();
        await this.checkout(data)
        this.$router.push({
          name: CHECKOUT_CREDIT_CARD_SUCCESS,
        });
      } finally {
        this.loading = false;
        this.changeLoading(false);
      }
      return true;
    },
    async checkout(data){
        try {
            const response = await this.execCheckout({
                chosenPlan: this.getChosenPlan,
                paymentMethod: "CreditCard",
                creditCardData: data
            });
            const statusResult = response.status;
            if (statusResult.toLowerCase() == "pending" ) {
              this.changePending(true)
            }
            if ( statusResult.toLowerCase() != "paid" && statusResult != "pending") {
                throw {
                  status: "rejected",
                  acquire: response.error
                }
            }
        } catch (error) {
          const message = this.handleAcquireError(error.errors[0].message)
          this.errorHandling(error, message);
        }
    },
    handleAcquireCode(error_code) {
      if (!error_code) {
        return this.$t("checkout.creditCard_error.rejected")
      }

      const invalidCardErros = {
        "1025": this.$t("checkout.creditCard_error.disabledCard"),
        "1011": this.$t("checkout.creditCard_error.cardNumberInvalid"),
        "1001": this.$t("checkout.creditCard_error.expiredCard")
      }
      const genericError = ["1009", "1040", "1004"]
      const refusedByBank = ["1016", "1007", "2000", "1000", "9113", "1019"]

      if (genericError.indexOf(error_code) != -1) {
        return this.$t("checkout.creditCard_error.genericError")
      }

      if (refusedByBank.indexOf(error_code) != -1) {
        return this.$t("checkout.creditCard_error.refusedByBank")
      }

      if (!invalidCardErros[error_code]) {
        return this.$t("checkout.creditCard_error.rejected")
      }

      return invalidCardErros[error_code]

    },
    async tokenData() {
      const creditCard = this.parseCreditCardData();
      const data = {
        creditCard: creditCard,
        gateway: process.env.VUE_APP_CURRENT_GATEWAY,
        customerId: this.storeProfile?.customerId,
        holder: creditCard.holder_name,
        brand: creditCard.payment_company_code,
        token: this.token,
        tokens: undefined,
      };
      try{
        const tokenizedData = await this.stripe.createToken(this.cardElement)
        data.token = tokenizedData.token.id;
      }catch(error){
        const text = this.$t("checkout.changePayment.creditCard.creditCardInvalid")
        this.errorHandling(error, text);
      }
      return data;
    },
    handleAcquireError(acquire) {
      if (!acquire) {
        return this.$t("checkout.creditCard_error.default")
      }

      const errorsCollection = {
        "CHARGE_INSUFFICIENT_FUNDS": this.$t("checkout.creditCard_error.insufficientFunds"),
        "CHARGE_BANK_REFUSED": this.$t("checkout.creditCard_error.refusedByBank"),
        "CHARGE_PAYMENT_REFUSED": this.$t("checkout.creditCard_error.refusedByBank"),
        "CHARGE_INVALID_CARD": this.$t("checkout.creditCard_error.genericError"),
        "CHARGE_BLOCKED_CARD":this.$t("checkout.creditCard_error.genericError"),
        "DISABLED_CARD": this.$t("checkout.creditCard_error.disabledCard"),
        "INVALID_CARD_NUMBER": this.$t("checkout.creditCard_error.cardNumberInvalid"),
        "CHARGE_EXPIRED_CARD": this.$t("checkout.creditCard_error.expiredCard")
      }

      if (!errorsCollection[acquire.code]) {
        return this.$t("checkout.creditCard_error.default")
      }

      return errorsCollection[acquire.code]
    },
    errorHandling(error, message) {
      this.$fire({
        text: message,
        type: "error",
        timer: 6000,
        customClass: "alert-error-1",
      });
      throw new Error(JSON.stringify(error));
    },
  },
};
</script>

<style>
.border{
  border: 1px solid #E0E6EB !important;
  border-radius: 0.25rem !important;
  margin: 0 0 25px 0 !important;
  padding: 5px 15px;
}
label {
  font-size: 14px;
  color: #818181;
  text-align: left !important;
  position: relative;
  width: 100%;
  margin: 0;
  display: block;
}
</style>


